<template lang="html">
  <div class="row">
    <div class="card mx-auto">
      <div class="card-header">
        <h4>Registruj Kompaniju</h4>
      </div>
      <div id="companyValidation">
        {{validationCompany}}
      </div>
      <div class="card-body">
        <form @submit.prevent="registerCompanyThis">
          <div class="form-group">
            <label for="name">Naziv Kompanije</label>
            <input
              class="form-control"
              type="text"
              placeholder=""
              name="name"
              v-model="name"
              id="name"
              >
          </div>

          <button class="btn btn-primary">Registruj Kompaniju</button>



        </form>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="card mx-auto">
      <div class="card-header">
        <h4>Registruj Sektor</h4>
      </div>
      <div id="companyValidation">
        {{validationSector}}
      </div>
      <div class="card-body">
        <form @submit.prevent="registerSectorThis">

          <div class="form-group">
            <label for="name">Naziv Sektora</label>
            <input
              class="form-control"
              type="text"
              placeholder=""
              name="sektor"
              v-model="sektor"
              id="sektor"
              >
          </div>

          <div class="form-group" >
            <label for="companys">Deo Kompanije:</label>
            <select name="type" id="type" v-model="type" >
               <option  v-for="company in companys" v-bind:key="company.name">{{company.name}}</option>
            </select>
          </div>

          <button class="btn btn-primary">Registruj Sektor</button>

        </form>

      </div>
    </div>
  </div>


  <div class="row">
    <div class="card mx-auto">
      <div class="card-header">
        <h4>Registruj Radno Mesto</h4>
      </div>
      <div id="companyValidation">
        {{validationRM}}
      </div>
      <div class="card-body">
        <form @submit.prevent="registerRadnoMestoThis">

          <div class="form-group">
            <label for="name">Naziv radnog mesta</label>
            <input
              class="form-control"
              type="text"
              placeholder=""
              name="radnoMesto"
              v-model="radnoMesto"
              id="radnoMesto"
              >
          </div>

          <div class="form-group" >
            <label for="companyRM">Deo Kompanije: </label>
            <select name="type" id="companyRM" v-model="companyRM" @change="onChange($event)">
               <option  v-for="company in companys" v-bind:key="company.name">{{company.name}}</option>
            </select>
            <label for="sectorRM">Deo Sektora: </label>
            <select name="type" id="sectorRM" v-model="sectorRM" >
               <option  v-for="sek in sektori" v-bind:key="sek">{{sek}}</option>
            </select>
          </div>

          <button class="btn btn-primary">Registruj radno mesto</button>

        </form>

      </div>
    </div>
  </div>

</template>

<script>
import {mapActions,mapGetters} from 'vuex';
export default {
  computed: mapGetters(['companys']),
  data(){
    return{
      name:"",
      sektor:"",
      type:"",
      sektori:[],
      companyRM:"",
      sectorRM:"",
      radnoMesto:"",
      validationCompany:"",
      validationSector:"",
      validationRM:"",
    }
  },
  methods:{
    ...mapActions(['registerCompany','getCompanies','registerSector','registerRadnoMesto']),
    registerCompanyThis(){
      // eslint-disable-next-line no-unused-vars
      this.validationCompany="";
      let company = {
        name:this.name,
        active: true,
      }
      if(company.name==""){
        this.validationCompany="Unesite naziv kompanije.";
      }
      for (var i = 0; i < this.companys.length; i++) {
        if(this.companys[i].name == company.name){
          this.validationCompany= "Kompanija već postoji.";
        }
      }
      if(this.validationCompany==""){
      this.registerCompany(company);
      this.getCompanies();
      this.validationCompany=`Registrovana kompanija ${company.name}`;
      }
    },
    registerSectorThis(){

      this.validationSector="";

      let sectorData = {
        companyName:this.type,
        sectorName:this.sektor
      }
      if(sectorData.companyName==""){
        this.validationSector="Unesite kompaniju.";
      }
      if(sectorData.sectorName==""){
        this.validationSector="Unesite naziv sektora.";
      }
      if(this.validationSector==""){
        this.registerSector(sectorData);
        this.getCompanies();
        this.validationSector=`Registrovan sektor ${sectorData.sectorName}`;
      }


    },
    registerRadnoMestoThis(){

      this.validationRM="";

      let rMData={
        companyName:this.companyRM ,
        sectorName: this.sectorRM,
        rMName: this.radnoMesto
      }
      if(rMData.companyName==""){
        this.validationRM="Unesite kompaniju.";
      }
      if(rMData.sectorName==""){
        this.validationRM="Unesite naziv sektora.";
      }
      if(rMData.rMName==""){
        this.validationRM="Unesite naziv radnog mesta.";
      }
      if(this.validationRM==""){
      this.registerRadnoMesto(rMData);
      this.getCompanies();
      this.validationRM=`Registrovano radno mesto ${rMData.rMName}`;
    }
    },
    onChange(event) {

        this.sektori = [];

        let tempCompany;
        for (var i = 0; i < this.companys.length; i++) {
          if(this.companys[i].name==event.target.value){
            tempCompany=this.companys[i];
          }
        }
        for ( i = 0; i < tempCompany.sektori.length; i++) {
          this.sektori.push(tempCompany.sektori[i].name);
        }

    }

  },
  created(){
    this.getCompanies();
  }
}
</script>

<style lang="css" scoped>
.row{

  padding-top: 5%;
  text-align: center;
  width:100%;


}
.card{
  width: 60%;
  border-radius: 0;
}
.btn{
  width: 60%;
  background-color: #003366;
  color: white;
  border-radius: 10px;
}
.form-group{
padding-top: 10px;
}
.form-control{
  border-radius: 0;
}
.card-header{
  background-color: #003366;
  color: white;
  border-radius: 10px;
}
#companyValidation{
  margin-top: 5px;
  background-color: #00aaaa;
}
</style>
